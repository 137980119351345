import React, { useEffect } from 'react';

const GoogleTagManager: React.FC = () => {
  useEffect(() => {
    // First script tag for gtag.js
    // const script1 = document.createElement('script');
    // script1.src = "https://www.googletagmanager.com/gtag/js?id=GT-TWZGT9P";
    // script1.defer = true; // Load asynchronously
    // document.body.appendChild(script1);

    // // Initialize gtag and dataLayer
    // window.dataLayer = window.dataLayer || [];
    // window.gtag = function (...args: any[]) {
    //   window.dataLayer.push(args);
    // };
    // window.gtag('js', new Date());
    // window.gtag('config', 'GT-TWZGT9P');

    // Second script for GTM
    const script2 = document.createElement('script');
    script2.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-T26T3NPB';
    script2.defer = true; // Load asynchronously
    document.body.appendChild(script2);

    // Cleanup script on component unmount
    return () => {
   //   document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return null; // This component does not render anything
};

export default GoogleTagManager;
